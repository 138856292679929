<template>
    <div v-if="user">
        <Nav/>
        <div class="wrapper">
            <!-- View load page -->
            <ModalSimple 
            v-model="viewLoadModal" 
            :disablePadding="true" 
            @close="() => { $store.state.loadManager.viewLoad = null }" 
            title="Load Details" 
            size="large" 
            :hideButtons="true"
            :loading="isLoading(['handleDriverChange', 'getListOfDrivers', 'getLoadDetails'])">
                <ViewLoadDetails/>
            </ModalSimple>
            
            <router-view></router-view>
        </div>
        <ModalCaller/>
    </div>
    <Login v-else/>
    
</template>

<script>
import { mapGetters } from 'vuex';
import Login from './Login.vue'
import Nav from '../components/Nav.vue'
import Footer from '../components/Footer.vue'
import ViewLoadDetails from '../components/ViewLoadDetails.vue'

    export default {
        components: {
            Login,
            Nav,
            ViewLoadDetails,
            Footer
        },
        data() {
            return {
                viewLoadModal: false
            }
        },
        computed: {
            ...mapGetters(['user', 'loadManager', 'axiosLoaders']),
            isViewingLoad() {
                if(this.loadManager.viewLoad) return true;
                return false;
            }
        },
        watch: {
            isViewingLoad() {
                this.viewLoadModal = this.isViewingLoad;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>