<template>
    <div class="contentGrid">


        <h3>Select documents to merge</h3>
        <div class="filesList">

            <div class="file">
                <div class="name">
                    <input type="checkbox" checked disabled/>
                    <span class="text">Rate Confirmation</span>
                </div>
                <div class="link">
                    <a :href="invoiceDetails.rateConfirmation" v-if="invoiceDetails.rateConfirmation" target="_blank">View</a>
                    <a href="" @click.prevent v-else>Not available</a>
                </div>
            </div>

            <div class="file" v-for="pod of PODs" :key="pod._id">
                <div class="name">
                    <input type="checkbox" name="pod" :true-value="[]" :value="pod.location" v-model="invoiceDetails.pod"/>
                    <span class="text">POD</span>
                </div>
                <div class="link"><a :href="apiURL + '/storage/files/' + pod._id" target="_blank">View</a></div>
            </div>

        </div>


        <div class="actionButtons">
            <button v-if="!result" class="default medium" @click="createInvoice" :disabled="isLoading(['HandleCreateInvoice'])">{{ isLoading(['HandleCreateInvoice']) ? 'Creating...' : 'Create invoice' }}</button>
            <a :href="result" target="_blank" class="default medium" @click="downloadInvoice" v-else>Download</a>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['options'],
        data() {
            return {
                load: this.options.load || {},
                invoiceDetails: {
                    pod: []
                },
                result: null
            }
        },
        computed: {
            apiURL() {
                return process.env.VUE_APP_BOARD_API_URL || '';
            },
            PODs() {
                return this.load.documents.filter(item => {
                    if(item.type === 'POD') return true;
                    return false;
                });
            }
        },
        methods: {
            createInvoice() {
                this.invoiceDetails.loadId = this.load._id;
                this.ajax('HandleCreateInvoice', {
                    url: `/loads/CreateInvoice/${this.load._id}`,
                    method: 'POST',
                    data: this.invoiceDetails
                }, (err, body) => {
                    if(!err && body.rateConPodPDF) {
                        this.result = body.rateConPodPDF;
                        return;
                    }
                });
            }
        },
        async mounted() {
            this.invoiceDetails.rateConfirmation = this.load.rateConfirmation && this.load.rateConfirmation.path ? this.apiURL + this.load.rateConfirmation.path : null;
        }
    }
</script>

<style lang="scss" scoped>

.filesList {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    .file {
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        border-bottom: 1px solid $borderColor;
        &:last-child {
            border: 0;
        }
        .name {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 10px;
            align-items: center;
        }
    }
}

.contentGrid {
    display: grid;
    row-gap: 20px;
}

.actionButtons {
    display: grid;
    justify-content: end;
}
</style>